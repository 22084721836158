import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { md } from 'utils/breakpoints';
import { PATH_HOME } from 'utils/constants/paths.js';

const Container = styled.div`
  display: none;

  @media (max-width: ${md}) {
    display: block;
  }
`;

const Main = (props) => {
  useEffect(() => {
    navigate(PATH_HOME, { replace: true });
  }, []);
  return <Container></Container>;
};

export default Main;
